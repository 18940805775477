import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Contacts from "./Contact/Contacts";
import Paper from "@material-ui/core/Paper";
import Agents from "./Agents";
import TabContainer from "./TabContainer";
import Script from "./CampaignScript/Script";
import CampaignDashboard from "./Campaign/CampaignDashboard";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

class CampaignTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Metrics" />
            <Tab label={"Scripts"} />
            <Tab label={"Agents"} />
            <Tab label={"Contacts"} />
          </Tabs>
        </Paper>
        {value === 0 && (
          <TabContainer>
            <CampaignDashboard campaign_id={this.props.campaign_id} />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <Script campaign_id={this.props.campaign_id} />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <Agents campaign_id={this.props.campaign_id} />
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            <Contacts campaign_id={this.props.campaign_id} />
          </TabContainer>
        )}
      </div>
    );
  }
}

CampaignTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignTabs);
