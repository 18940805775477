
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withAuth0 } from "@auth0/auth0-react";
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CreateScript from './CreateScript';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';



const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class Script extends Component {

  constructor(props) {
    super(props);

    this.state = {
      message: "",
      isLoading: true,
      script: null,
      createScript: false
    };
    this.getScript();
  }

  getScript = async () => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/get/" + this.props.campaign_id.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) =>
        this.setState({ script: response.blocks, isLoading: false })
      )
      .catch((error) => this.setState({ message: error.message }));
  }


  deleteScript = async () => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ script_id: this.state.script[0].script_id })
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) => {
        this.setState({ isLoading: false });
        this.getScript();
      })
      .catch((error) => this.setState({ message: error.message }));
  }

  setCreateScript = () => {
    this.setState({ createScript: true })
  }

  existCreateScript = () => {
    this.setState({ createScript: false })
    this.getScript();
  }


  handleTextChange = (block) => (event) => {
    block.selected_response = event.target.value;
  }

  handleDropdownChange = (block) => (event) => {
    block.selected_response = event.target.value;
  };

  handleRadioChange = (block) => (event) => {
    block.selected_response = event.target.value;
  }

  displayCaptureBlock = (block) => {
    if (block && block.response_type_id) {


      if (block.response_type_id === 1) {
        //radio
        return (<FormControl component="fieldset">
          <FormLabel component="legend">Capture</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={block.selected_response} onChange={this.handleRadioChange(block)}>
            {block.response_values.map((option,index) => { if(option){
            return (<FormControlLabel key={index+option} value={option} control={<Radio />} label={option} />)
          }})}
          </RadioGroup>
        </FormControl>)


      } else if (block.response_type_id === 2) {
        //dropdown
        return (<TextField
          id="standard-select-currency"
          select
          fullWidth
          label="Capture"
          value={block.selected_response}
          onChange={this.handleDropdownChange(block)}
        >
          {block.response_values.map((option,index) => {
            if(option){
              return (<MenuItem key={option+index} value={option}>{option}</MenuItem>)
            }
            })}
        </TextField>);
      } else {
        //textbox
        return (<TextField
          id="outlined-multiline-static"
          label="Capture"
          multiline
          rows={4}
          value={block.selected_response}
          onChange={this.handleTextChange(block)}
          variant="outlined"
        />)
      }
    }
  }

  render() {

    let scriptDisplay

    if (this.state.script) {
      var blocks = this.state.script.map((block, index) => {
        return (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {block.script_title}
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <p style={{whiteSpace: "pre-wrap"}}>{block.script_block_text}</p>
                  </Grid>
                  <Grid item xs={6}>
                    {this.displayCaptureBlock(block)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      })
    }


    if (this.state.isLoading) {
      scriptDisplay = <CircularProgress />;
    } else if (this.state.createScript) {
      scriptDisplay = (
        <CreateScript existCreateScript={this.existCreateScript} campaign_id={this.props.campaign_id.id}></CreateScript>
      )
    } else if (!this.state.script || this.state.script.length === 0) {
      scriptDisplay = (<Grid container spacing={5}>
        <Grid item xs={8}>
          <h4>Please create a script to start your campaign.</h4>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={this.setCreateScript}>Create Script</Button>
        </Grid>
      </Grid>)
    } else {
      scriptDisplay = (<div><Grid container spacing={5}>
        <Grid item xs={8}>
          <span>There is a script for this campaign. If you need to edit the script make a new campaign.</span>
        </Grid>
        <Grid item xs={4}>
          {/* <Button variant="contained" onClick={this.deleteScript}>Delete Script</Button> */}
        </Grid>
      </Grid >
        <Card style={{ marginTop: 25 }}>
          <CardContent>
            <h3>Contact Information</h3>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5>Name: John Doe</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <h5>Phone: +15555555555</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <h5>Address: 1234 example lane</h5>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5>City: Milwaukee</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <h5>State: Wisconsin</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <h5>Zip: 55555</h5>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={4}>
                        <h5>Gender: M</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <h5>Polling location: n/a</h5>
                      </Grid>
                      <Grid item xs={4}>
                        <h5>Party: Democrat</h5>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={2} style={{ marginTop: 25 }}>
          {blocks}
        </Grid>
      </div>
      )
    }





    return (
      <div>
        {scriptDisplay}
      </div>
    );
  }

}


export default (withAuth0(Script));