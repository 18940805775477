import React, { Component } from "react";
import { Button } from "@material-ui/core";
import MassImportTable from "./MassImportTable";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CSVToArray from "./csvParser";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import CsvTemplate from "../../TemplateFiles/ImportTemplate.csv";
import { CSVReader } from "react-papaparse";
import { withAuth0 } from "@auth0/auth0-react";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;
const buttonRef = React.createRef();

class MassImport extends Component {
  constructor(props) {
    super();

    this.state = {
      contacts: [],
      displayModal: false,
      successfullyImportedCount: 0,
      optoutContacts: 0,
      uploadLimit: false,
    };
  }

  insertContacts = async () => {
    if (this.state.contacts.length < 1) {
      return;
    }
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    let data = {
      campaign_id: this.props.match.params.id,
      contacts: this.state.contacts.filter(
        (contact) => contact.errors.length == 0
      ),
    };
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        let contentsLength = response.contacts[0].insert_bulk_contacts
          ? response.contacts[0].insert_bulk_contacts
          : 0;
        let optoutContactAmount =
          response.optouts[0].get_optout_contacts.length;
        this.setState({
          contacts: [],
          successfullyImportedCount: contentsLength,
          optoutContacts: optoutContactAmount,
          displayModal: true,
        });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  handleOnFileLoad = (data) => {
    if (data.length < 100000) {
      data = data.filter((item) => item.errors.length === 0);
      if (this.state.contacts.length > 0) {
        this.setState({
          contacts: data.concat(this.state.contacts),
          uploadLimit: false,
        });
      } else {
        this.setState({ contacts: data, uploadLimit: false });
      }
    } else {
      this.setState({ uploadLimit: true });
    }
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  closeModal = () => {
    this.setState({ displayModal: false, successfullyImportedCount: null });
  };

  render() {
    let contactArea;
    if (this.state.contacts.length !== 0 && this.state.uploadLimit === false) {
      contactArea = <MassImportTable rows={this.state.contacts} />;
    } else if (this.state.uploadLimit) {
      contactArea = (
        <Paper>
          <Typography variant="h5" component="h3">
            Unable to upload more than 100,000 contacts at one time.
          </Typography>
        </Paper>
      );
    } else {
      contactArea = (
        <Paper>
          <Typography variant="h5" component="h3">
            Hey Looks like there are no contacts, try uploading some.
          </Typography>
        </Paper>
      );
    }

    return (
      <div>
        <Dialog open={this.state.displayModal}>
          <Card>
            <CardContent>
              <h3>
                {" "}
                {this.state.successfullyImportedCount} contacts added to the
                campaign
              </h3>
              <h3>
                {this.state.optoutContacts} have opted out of being contacted
              </h3>
            </CardContent>
            <CardActions>
              <Button onClick={this.closeModal}>OK</Button>
            </CardActions>
          </Card>
        </Dialog>
        <Grid container spacing={4}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <h1>Mass Upload</h1>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={6} />
          <Grid container spacing={6} item xs={5}>
            <Grid item xs={8}>
              <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnError}
                config={{ header: true }}
                noClick
                noDrag
              >
                {({ file }) => (
                  <aside
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 10,
                    }}
                  >
                    <button
                      type="button"
                      onClick={this.handleOpenDialog}
                      style={{
                        width: "40%",
                        borderRadius: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      Browse local files
                    </button>
                    <div
                      style={{
                        width: "60%",
                        height: 45,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#ccc",
                        marginTop: 5,
                        marginBottom: 5,
                        paddingLeft: 13,
                        paddingTop: 3,
                        lineHeight: 2.5,
                      }}
                    >
                      {file ? file.name : ''}
                    </div>
                  </aside>
                )}
              </CSVReader>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="get_app"
                download={"ImportTemlate.csv"}
                href={CsvTemplate}
              >
                Download Template
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={1} />
          <Grid item xs={10}>
            {contactArea}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1} />
          <Grid item xs={6} />
          <Grid container item xs={5}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                onClick={this.insertContacts}
                variant="contained"
                color="primary"
                disabled={this.state.uploadLimit}
              >
                Save Contacts
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.props.history.goBack}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </div>
    );
  }
}

export default withAuth0(MassImport);

