import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withAuth0 } from "@auth0/auth0-react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import CountUp from "react-countup";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class AgentCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      agent: null,
      contact: null,
      isLoading: false,
      script: null,
      noMoreContacts: false,
      selected_code: 0,
      codeValues: [],
      agentIsCalling: true,
      loadingCallAndScript: false,
      disableFields: false,
      callStats: null,
    };
    this.kickoffNextCall(true);
  }

  getScript = async () => {
    const id = this.props.campaign_id;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    return await fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/get/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) => {
        this.setState({
          script: response.blocks,
          codeValues: response.codes,
          selected_code: null,
          isLoading: false,
          disableFields: false,
        });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  getCampaignStats = async () => {
    let id = this.props.campaign_id;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/stats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ campaign_id: id }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({ callStats: response[0] });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  kickoffNextCall = async (make_call) => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true, agentIsCalling: true });
    this.getCampaignStats();
    await fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/call/next", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        campaign_id: this.props.campaign_id,
        make_call: make_call,
      }),
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) => {
        this.setState({
          agent: response.agent,
          contact: response.contact,
          isLoading: false,
          agentIsCalling: true,
        });
        this.getScript();
        this.getCampaignStats();
        return;
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  captureData = async () => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ disableFields: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/capture", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        campaign_id: this.props.campaign_id,
        blocks: this.state.script,
        selected_code: this.state.selected_code,
        contact_id: this.state.contact.contact_id,
      }),
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then(async (response) => {
        //await this.getScript();
        this.setState({ agentIsCalling: false });
        this.getCampaignStats();
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  handleNextCall = () => {
    this.getScript();
    this.kickoffNextCall(true);
  };

  handleTextChange = (block) => (event) => {
    var textChange = [...this.state.script];
    var location = textChange.findIndex((item) => item === block);
    textChange[location].selected_response = event.target.value;
    this.setState({ script: textChange });
  };

  handleDropdownChange = (block) => (event) => {
    var blocks = [...this.state.script];
    var location = blocks.findIndex((item) => item === block);
    blocks[location].selected_response = event.target.value;
    this.setState({ script: blocks });
  };

  handleRadioChange = (block) => (event) => {
    var blocks = [...this.state.script];
    var location = blocks.findIndex((item) => item === block);
    blocks[location].selected_response = event.target.value;
    this.setState({ script: blocks });
  };

  handleCodeChange = (event) => {
    this.setState({ selected_code: event.target.value });
  };

  handleCompleteCallClick = () => {
    this.captureData();
  };

  displayCaptureBlock = (block) => {
    if (block && block.response_type_id) {
      if (block.response_type_id === 1) {
        //radio
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">Capture</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={block.selected_response}
              onChange={this.handleRadioChange(block)}
            >
              {block.response_values.map((option, index) => {
                if (option) {
                  return (
                    <FormControlLabel
                      disabled={this.state.disableFields}
                      key={index + option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  );
                }
              })}
            </RadioGroup>
          </FormControl>
        );
      } else if (block.response_type_id === 2) {
        //dropdown
        return (
          <TextField
            id="standard-select-currency"
            select
            fullWidth
            label="Capture"
            value={block.selected_response}
            disabled={this.state.disableFields}
            onChange={this.handleDropdownChange(block)}
          >
            {block.response_values.map((option, index) => {
              if (option) {
                return (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                );
              }
            })}
          </TextField>
        );
      } else {
        //textbox
        return (
          <TextField
            id="outlined-multiline-static"
            label="Capture"
            multiline
            rows={4}
            value={block.selected_response}
            disabled={this.state.disableFields}
            onChange={this.handleTextChange(block)}
            variant="outlined"
          />
        );
      }
    }
  };

  render() {
    const { contact } = this.state;

    if (this.state.script != null) {
      var blocks = this.state.script.map((block, index) => {
        return (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {block.script_title}
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {block.script_block_text}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    {this.displayCaptureBlock(block)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      });
    }

    let display;

    if (this.state.isLoading) {
      display = <CircularProgress />;
    } else if (!this.state.contact) {
      display = (
        <h2 style={{ marginTop: 50 }}>There are no more contacts to call.</h2>
      );
    } else if (this.state.contact && this.state.agent) {
      display = (
        <div>
          <Grid container spacing={2} style={{ marginTop: 25 }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <h3>Campaign Call Progress</h3>
                  <Grid container spacing={3} style={{ textAlign: "center" }}>
                    <Grid item xs={3}>
                      <Card>
                        <CardContent>
                          <Typography color="textSecondary" gutterBottom>
                            Total Records Loaded
                          </Typography>
                          <CountUp
                            duration={3}
                            end={
                              this.state.callStats
                                ? this.state.callStats.total_calls
                                : 0
                            }
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card>
                        <CardContent>
                          <Grid container justify="center">
                            <Typography
                              color="textSecondary"
                              style={{ width: "100%" }}
                            >
                              Total Records Dialed
                            </Typography>
                            <br />
                            <CountUp
                              duration={3}
                              end={
                                this.state.callStats
                                  ? parseInt(
                                      this.state.callStats.calls_pending_left
                                    ) +
                                    parseInt(
                                      this.state.callStats.calls_complete
                                    )
                                  : 0
                              }
                            />
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography color="textSecondary" gutterBottom>
                                Redial Needed
                              </Typography>
                              <CountUp
                                duration={3}
                                end={
                                  this.state.callStats
                                    ? this.state.callStats.calls_pending_left
                                    : 0
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography color="textSecondary" gutterBottom>
                                Finalized
                              </Typography>
                              <CountUp
                                duration={3}
                                end={
                                  this.state.callStats
                                    ? this.state.callStats.calls_complete
                                    : 0
                                }
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card>
                        <CardContent>
                          <Typography color="textSecondary" gutterBottom>
                            Total Remaining
                          </Typography>
                          <CountUp
                            duration={3}
                            end={
                              this.state.callStats
                                ? this.state.callStats.calls_left
                                : 0
                            }
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <h3>Contact Information</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={4}>
                              <h5>
                                Name:{" "}
                                {this.state.contact.first_name +
                                  " " +
                                  this.state.contact.last_name}
                              </h5>
                            </Grid>
                            <Grid item xs={4}>
                              <h5>Phone: {contact.phone}</h5>
                            </Grid>
                            <Grid item xs={4}>
                              <h5>Address: {contact.address_1}</h5>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={4}>
                              <h5>City: {contact.city}</h5>
                            </Grid>
                            <Grid item xs={4}>
                              <h5>State: {contact.state}</h5>
                            </Grid>
                            <Grid item xs={4}>
                              <h5>Zip: {contact.zip}</h5>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={2}>
                              <h5>Gender: {contact.gender}</h5>
                            </Grid>
                            <Grid item xs={8}>
                              <h5>
                                Polling location: {contact.polling_location}
                              </h5>
                            </Grid>
                            <Grid item xs={2}>
                              <h5>Party: {contact.party}</h5>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {blocks}
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <TextField
                id="outlined-select-currency"
                select
                label="Response Code"
                disabled={this.state.disableFields}
                value={this.state.selected_code}
                onChange={this.handleCodeChange}
                //hide={this.state.agentIsCalling}
                helperText="Select Response Code"
                variant="outlined"
                style={{ marginTop: 25 }}
              >
                {this.state.codeValues.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.code}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                disabled={
                  !this.state.agentIsCalling ||
                  this.state.selected_code === null
                }
                onClick={this.handleCompleteCallClick}
                color="primary"
                style={{ float: "right", fontSize: 13, marginTop: 25 }}
              >
                Complete Current Call
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                disabled={this.state.agentIsCalling}
                color="primary"
                onClick={this.handleNextCall}
                style={{ float: "right", fontSize: 25, marginTop: 25 }}
              >
                Next Call
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }

    return <div>{display}</div>;
  }
}

export default withAuth0(AgentCampaign);
