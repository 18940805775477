import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withAuth0 } from "@auth0/auth0-react";
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AgentCalling from './AgentCalling';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';


const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;


class AgentCampaign extends Component {

  constructor(props) {
    super(props);

    this.state = {
      message: "",
      isLoading: false,
      script: null,
      calling: false,
      agent: ''
    };
    this.getScript();
    this.getAgentCallDetails();
  }


  getScript = async () => {
    const { id } = this.props.match.params;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/get/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) =>
        this.setState({ script: response.blocks, isLoading: false })
      )
      .catch((error) => this.setState({ message: error.message }));
  }


  getAgentCallDetails = async () => {
    const { id } = this.props.match.params;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/call/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) =>
        this.setState({ agent: response.agent, isLoading: false })
      )
      .catch((error) => this.setState({ message: error.message }));
  }


  handleStartCalling = () => {
    this.setState({ calling: true });
  }

  handleTextChange = (block) => (event) => {
    block.selected_response = event.target.value;
  }

  handleDropdownChange = (block) => (event) => {
    block.selected_response = event.target.value;
  };

  handleRadioChange = (block) => (event) => {
    block.selected_response = event.target.value;
  }

  displayCaptureBlock = (block) => {
    if (block.response_type_id === 1) {
      //radio
      return (<FormControl component="fieldset">
        <FormLabel component="legend">Capture</FormLabel>
        <RadioGroup aria-label="gender" name="gender1" value={block.selected_response} onChange={this.handleRadioChange(block)}>
          {block.response_values.map((option) => (
            <FormControlLabel value={option} control={<Radio />} label={option} />
          ))}
        </RadioGroup>
      </FormControl>)


    } else if (block.response_type_id === 2) {
      //dropdown
      return (<TextField
        id="standard-select-currency"
        select
        fullWidth
        label="Capture"
        value={block.selected_response}
        onChange={this.handleDropdownChange(block)}
      >
        {block.response_values.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>);
    } else {
      //textbox
      return (<TextField
        id="outlined-multiline-static"
        label="Capture"
        multiline
        rows={4}
        onChange={() => this.handleTextChange(block)}
        variant="outlined"
      />)
    }
  }

  render() {




    if (this.state.script) {
      var blocks = this.state.script.map((block, index) => {
        return (

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {block.script_title}
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <p style={{whiteSpace: "pre-wrap"}}>{block.script_block_text}</p>
                  </Grid>
                  <Grid item xs={6}>
                    {this.displayCaptureBlock(block)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      })
    }

    let display = '';
    if (this.state.calling) {
      display = (<AgentCalling campaign_id={this.props.match.params.id}></AgentCalling>)
    } else {
      display = (<div><h2>Welcome Agent, to Blu-Dial!</h2>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <h3>Script Preview</h3>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={this.handleStartCalling}>Start Calling</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <h3>Calling details</h3>
                <h4>Call in and enter access code to validate phone number for calling.</h4>
                <h5>Phone: {this.state.agent.campaign_phone}</h5>
                <h5>Agent Access Code: {this.state.agent.access_code}</h5>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Card >
              <CardContent>
                <h3>Contact Information</h3>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={4}>
                            <h5>Name: John Doe</h5>
                          </Grid>
                          <Grid item xs={4}>
                            <h5>Phone: +15555555555</h5>
                          </Grid>
                          <Grid item xs={4}>
                            <h5>Address: 1234 example lane</h5>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4}>
                            <h5>City: Milwaukee</h5>
                          </Grid>
                          <Grid item xs={4}>
                            <h5>State: Wisconsin</h5>
                          </Grid>
                          <Grid item xs={4}>
                            <h5>Zip: 55555</h5>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={4}>
                            <h5>Gender: M</h5>
                          </Grid>
                          <Grid item xs={4}>
                            <h5>Polling location: n/a</h5>
                          </Grid>
                          <Grid item xs={4}>
                            <h5>Party: Democrat</h5>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>



        <Grid container spacing={2} style={{ marginTop: 25 }}>
          {blocks}
        </Grid>
      </div>)
    }


    return (<div>
      {display}
    </div>)

  }
}

export default withAuth0(AgentCampaign);
