import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import States from "./states.json";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
  }
});

class CreateCampaignDialog extends Component {
  state = {
    name: "",
    description: "",
    city: "",
    state: "",
    areaCode: "",
    nameRequired: false,
    cityRequired: false,
    stateRequired: false,
    areaCodeRequired: false
  };

  handleCreate = () => {
    let campaign = {
      name: this.state.name,
      description: this.state.name,
      city: this.state.city,
      state: this.state.state,
      areaCode: this.state.areaCode
    };
    this.setState({
      nameRequired: false,
      stateRequired: false,
      cityRequired: false,
      areaCodeRequired: false
    });
    var nameR = false;
    var cityR = false;
    var stateR = false;
    var areaR = false;

    if (this.state.name === "") {
      nameR = true;
      this.setState({ nameRequired: true });
    }
    if (this.state.city === "") {
      cityR = true;
      this.setState({ cityRequired: true });
    }
    if (this.state.state === "") {
      stateR = true;
      this.setState({ stateRequired: true });
    }
    if (this.state.areaCode === ""){
      areaR = true;
      this.setState({areaCodeRequired: true});
    }
    if (nameR === false && cityR === false && stateR === false) {
      this.props.onClose(campaign);
    }
    
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleStateChange = (event) => {
    this.setState({ state: event.target.value });
  };

  onCancel = () => {
    this.setState({ state: "" });
    this.props.onClose(null);
  };

  renderOptions() {
    return States.map((dt, i) => {
      //console.log(dt);
      return (
        <MenuItem
          label="Select a country"
          value={dt.name}
          key={i}
          name={dt.name}
        >
          {dt.name}
        </MenuItem>
      );
    });
  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-campaign-dialog"
      >
        <DialogTitle id="create-campaign-dialog">Create a Campaign</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            error={this.state.nameRequired}
            id="create-campaign-name"
            label="Name"
            className={classes.textField}
            onChange={this.handleChange("name")}
            margin="normal"
            name="campaign.name"
          />

          <TextField
            error={this.state.cityRequired}
            required
            id="create-campaign-city"
            label="City"
            className={classes.textField}
            margin="normal"
            onChange={this.handleChange("city")}
            name="comapaign.city"
          />
          
          <TextField
            error={this.state.areaCodeRequired}
            required
            id="create-campaign-areaCode"
            label="Phone Area Code"
            className={classes.textField}
            margin="normal"
            onChange={this.handleChange("areaCode")}
            name="campaign.areaCode"
          />
          <FormControl className={classes.formControl}>
              <InputLabel htmlFor="country-select">State *</InputLabel>
              <Select
                id="create-campaign-state"
                value={this.state.state}
                error={this.state.stateRequired}
                required
                onChange={this.handleStateChange}
                input={<Input id="country-select" />}
              >
                {this.renderOptions()}
              </Select>
            </FormControl>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={this.onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCreate} color="primary" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateCampaignDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default withStyles(styles)(CreateCampaignDialog);
