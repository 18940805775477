import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    emailError: false,
  },
});

class CreateAgentDialog extends Component {
  state = {
    name: "",
    phone: "",
    phoneError: false,
  };

  handleCreate = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email.match(emailRegex)) {
      let agent = {
        name: this.state.name,
        email: this.state.email,
      };
      this.props.onClose(agent);
    } else {
      this.setState({ emailError: true });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
      >
        <DialogTitle id="create-group-dialog">Add an Agent</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="create-agent-name"
            label="First Name"
            className={classes.textField}
            onChange={this.handleChange("name")}
            margin="normal"
            name="agent.name"
          />
          <TextField
            error={this.state.emailError}
            required
            autoFocus
            id="create-agent-email"
            label="Email"
            className={classes.textField}
            onChange={this.handleChange("email")}
            margin="normal"
            name="agent.email"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCreate} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateAgentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(CreateAgentDialog);
