
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withAuth0 } from "@auth0/auth0-react";
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';



const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class CreateScript extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: true,
          campaigns: [],
          message: "",
          isLoading: false,
          openDialog: false,
          scriptBlocks: [],
          responseOptions: [],
          selectedResponseOption: null
        };
        this.getResponseOptions();
      }

      getResponseOptions = async () => {
        const { getAccessTokenSilently } = this.props.auth0;
        const token = await getAccessTokenSilently();
        fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/options/get", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(
            (result) => {
              if (result.ok) return result.json();
              throw new Error("Network response was not ok.");
            },
            (error) => {
              console.log("error:" + error);
            }
          )
          .then((response) =>
            this.setState({ responseOptions: response })
          )
          .catch((error) => this.setState({ message: error.message }));
      }

      saveScript = async () => {
        const { getAccessTokenSilently } = this.props.auth0;
        const token = await getAccessTokenSilently();
        var scriptCreatedBlocks = [...this.state.scriptBlocks];
        scriptCreatedBlocks.forEach(element => {
          if(element.response_option_values.length > 0){
          element.response_option_values = element.response_option_values.split(",")
        }});
        fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/script/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({campaign_id: this.props.campaign_id,scriptBlocks: scriptCreatedBlocks})
        })
          .then(
            (result) => {
              if (result.ok) return result.json();
              throw new Error("Network response was not ok.");
            },
            (error) => {
              console.log("error:" + error);
            }
          )
          .then((response) => {
            this.props.existCreateScript();
          }
          )
          .catch((error) => this.setState({ message: error.message }));
      }


      handleChange = name => event => {
        this.setState({ [name]: event.target.value });
      };
    

      AddScriptBlock = () => {
        var addScript = [...this.state.scriptBlocks];
        addScript.push({id:  this.state.scriptBlocks.length,
             script_text: "", response_option_id: 0, response_option_values: []});
        this.setState({ scriptBlocks: addScript });
      };
    
      RemoveScriptBlock = (index) => {
        var removeScript = [...this.state.scriptBlocks];
        removeScript.splice(index, 1);
        this.setState({ scriptBlocks: removeScript });
      };

      handleTitleChange = (script) => (event) =>{
        var titleChange = [...this.state.scriptBlocks];
        var location = titleChange.findIndex((item) => item.id === script.id);
        titleChange[location].script_title = event.target.value;
        this.setState({ scriptBlocks: titleChange });
      }
    
      handleTextChange = (script) => (event) => {
        var textChange = [...this.state.scriptBlocks];
        var location = textChange.findIndex((item) => item.id === script.id);
        textChange[location].script_text = event.target.value;
        this.setState({ scriptBlocks: textChange });
      }; 

      handleResponseChange = (block) => (event) => {
        var scripts = [...this.state.scriptBlocks];
        var location = scripts.findIndex((item) => item.id === block.id);
        scripts[location].response_option_id = event.target.value;
        this.setState({scriptBlocks: scripts});
      }; 

      handleResponseOptionsChange = (block) => (event) => {
        var scripts = [...this.state.scriptBlocks];
        var location = scripts.findIndex((item) => item.id === block.id);
        scripts[location].response_option_values = event.target.value;
        this.setState({scriptBlocks: scripts});
      }; 


      render() {


        let responseOptionsDisplay 
        
        if(this.state.responseOptions){
            responseOptionsDisplay = this.state.responseOptions.map((option,index) => {
                return (<MenuItem key={index} value={option.response_type_id} name={option.response_type}>{option.response_type}</MenuItem>)
             })
        }

        var renderBlocks = this.state.scriptBlocks.map((block, index) => {
            return (<Grid item xs={12} key={block.id}>
                <Card >
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={5}>
                <TextField
                            id="outlined-multiline-static"
                            label="Block Title"
                            fullWidth
                            variant="outlined"
                            onChange={this.handleTitleChange(block)}
                  />
                </Grid>
                <Grid item xs={6}>

                </Grid>
              </Grid>
              
                <Grid container spacing={5}>
                    <Grid item xs={5}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Block Text"
                            multiline
                            fullWidth
                            rows={4}
                            variant="outlined"
                            onChange={this.handleTextChange(block)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                            <InputLabel id="response-select-id">Capture Options</InputLabel>
                                <Select
                                    labelId="response-select-id"
                                    id="demo-simple-select"
                                    value={block.response_option_id }
                                    onChange={this.handleResponseChange(block)}                            
                                >
                                    {responseOptionsDisplay}
                                </Select>
                            </Grid>
                            <Grid item xs={8}>                            
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Comma Separated Responses"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    variant="outlined"
                                    onChange={this.handleResponseOptionsChange(block)}                                
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={() => this.RemoveScriptBlock(index)}>Remove Block</Button>
                    </Grid>                    
                </Grid>                
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
          </Grid>
          )
        });


          return (
            <div>
                <Grid container spacing={5}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={this.AddScriptBlock}>Add Block</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={this.saveScript}>Save Script</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>                    
                    {renderBlocks}
                </Grid>
            </div>
          );
      }

    }


      export default (withAuth0(CreateScript));