import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { NavLink as RouterLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    "text-decoration": "none",
    color: "white",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const { logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h5"
            to="/"
            component={RouterLink}
            className={classes.title}
          >
            Blu-Dial
          </Typography>
          <Button color="inherit" onClick={() => logoutWithRedirect()}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
