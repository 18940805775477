import React from "react";
import { Router, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Campaigns from "./views/Campaign/Campaigns";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import history from "./utils/history";
import CircularProgress from "@material-ui/core/CircularProgress";
import CampaignLanding from "./views/CampaignLanding";
import MassImport from "./views/MassImport/MassImport";
import AdminRoute from "./views/AdminRoute";
import AgentRoute from "./views/AgentRoute";
import AgentCampaign from "./views/AgentCampaign";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import AgentCampaigns from "./views/AgentCampaigns";
initFontAwesome();

const App = () => {
  const { loading, user } = useAuth0();
  console.log(user);
  if (loading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <AdminRoute
              path="/"
              exact
              component={Campaigns}
              redirectUrl="/agent/campaigns"
            />
            <AgentRoute path="/agent/campaign/:id" component={AgentCampaign} />
            <AgentRoute path="/agent/campaigns" component={AgentCampaigns} />
            <AdminRoute path="/campaign/:id" component={CampaignLanding} />
            <AdminRoute path="/massupload/:id" component={MassImport} />
          </Switch>
        </Container>
      </div>
    </Router>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <CircularProgress />,
});
