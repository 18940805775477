import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import { withAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import CountUp from "react-countup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    textAlign: "center",
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px",
  },
  textPadding: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  removeButtonMargin: {
    marginBottom: "20px",
  },
  settingTitle: {
    marginTop: "20px",
  },
});

class CampaignDashboard extends Component {
  state = {
    open: false,
    isLoading: false,
    reports: [],
    generatedReport: [1],
    callStats: null,
  };

  componentDidMount() {
    this.getReports();
    this.getCampaignStats();
  }

  getReports = async () => {
    //need to finish
    let { id } = this.props.campaign_id;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/reports/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) =>
        this.setState({
          reports: response,
          generatedReport: [1],
          isLoading: false,
        })
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  generateReport = async () => {
    let { id } = this.props.campaign_id;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/responses/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((res) => {
        this.getReports();
        this.setState({ generatedReport: res });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  refreshReports = () => {
    this.getReports();
  };

  getCampaignStats = async () => {
    let { id } = this.props.campaign_id;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/stats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ campaign_id: id }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((response) => {
        this.setState({ callStats: response[0] });
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  generate = (element) => {
    return this.state.reports.map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  };

  render() {
    const { classes } = this.props;
    const { reports } = this.state;

    let reportButtons = (
      <div>
        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.generateReport}
              className={classes.button}
            >
              Generate Report
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.refreshReports}
              className={classes.button}
            >
              refresh
            </Button>
          </Grid>
        </Grid>
      </div>
    );

    let reportMessage;

    if (this.state.isLoading) {
      reportMessage = <span>Loading Reports...</span>;
    } else if (reports.length == 0) {
      reportMessage = <span>There is no data or reports that exist.</span>;
    } else if (
      this.state.generatedReport &&
      this.state.generatedReport.length == 0
    ) {
      reportMessage = <span>There was no data to generate a new report.</span>;
    } else reportMessage = "";

    let reportList = (
      <List>
        {reports.map((value) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <a href={value.url_path}>
              <ListItemText primary={value.name}></ListItemText>
            </a>
          </ListItem>
        ))}
      </List>
    );

    return (
      <div>
        <Grid container>
          <Grid item xs={5}>
            {reportButtons}
            {reportMessage}
            {reportList}
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Total Records Loaded
                    </Typography>
                    <CountUp
                      style={{ marginLeft: "50%" }}
                      duration={3}
                      end={
                        this.state.callStats
                          ? this.state.callStats.total_calls
                          : 0
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.root}>
                  <CardContent>
                    <Grid container justify="center">
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        style={{ width: "100%" }}
                      >
                        Total Records Dialed
                      </Typography>
                      <CountUp
                        duration={3}
                        end={
                          this.state.callStats
                            ? parseInt(
                                this.state.callStats.calls_pending_left
                              ) + parseInt(this.state.callStats.calls_complete)
                            : 0
                        }
                      />
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Redial Needed
                        </Typography>
                        <CountUp
                          style={{ marginLeft: "50%" }}
                          duration={3}
                          end={
                            this.state.callStats
                              ? this.state.callStats.calls_pending_left
                              : 0
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Finalized
                        </Typography>
                        <CountUp
                          style={{ marginLeft: "50%" }}
                          duration={3}
                          end={
                            this.state.callStats
                              ? this.state.callStats.calls_complete
                              : 0
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Total Remaining
                    </Typography>
                    <CountUp
                      style={{ marginLeft: "50%" }}
                      duration={3}
                      end={
                        this.state.callStats
                          ? this.state.callStats.calls_left
                          : 0
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CampaignDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuth0(CampaignDashboard));
