import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withAuth0 } from "@auth0/auth0-react";

var userIsInRoles = function (userRoles, requiredRoles) {
  return requiredRoles.every((role) => userRoles.includes(role));
};

function RoleRoute({
  component: Component,
  requiredRoles,
  redirectUrl,
  auth0,
  ...rest
}) {
  //const { user } = auth0;
  return (
    <Route
      {...rest}
      render={(props) => {
        //Display message if user lacks required scope(s).
        if (
          !userIsInRoles(
            auth0.user[`${process.env.REACT_APP_DOMAIN}/roles`],
            requiredRoles
          )
        ) {
          if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
          } else {
            return <h1>Unauthorized - You don't have the correct roles.</h1>;
          }
        }

        console.log("here");
        //Render component
        return <Component {...props} />;
      }}
    />
  );
}

RoleRoute.propTypes = {
  //component: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
};

export default withAuth0(RoleRoute);
