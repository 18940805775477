import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import CreateAgentDialog from "./CreateAgentDialog";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withAuth0 } from "@auth0/auth0-react";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class Agents extends Component {
  state = {
    open: false,
    agents: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getAgents();
  }

  getAgents = async () => {
    let { id } = this.props.campaign_id;
    this.setState({ isLoading: true });
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/get/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cache: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            agents: result,
            isLoading: false,
          });
        },
        (error) => {
          console.log("error:" + error);
        }
      );
  };

  createAgent = async (newAgent, _callback) => {
    let { id } = this.props.campaign_id;
    newAgent.campaign_id = id;
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newAgent),
    })
      .then(
        (result) => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  markAgentInactive = async (agentId, _callback) => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    const { id } = this.props.campaign_id;
    const body = { campaign_id: id, agent_id: agentId };
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/agent/delete/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then(
        (result) => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  CreateAgentButtonClick = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (agentToAdd) => {
    if (!agentToAdd.target) {
      this.createAgent(agentToAdd, this.getAgents);
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "first_name",
        label: "First Name",
        options: {
          sort: true,
          sortDirection: "asc",
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          sort: true,
        },
      },
      {
        name: "id",
        label: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                color="error"
                aria-label="Delete"
                onClick={() => this.markAgentInactive(value, this.getAgents)}
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
    ];

    const options = {
      print: false,
      download: false,
      selectableRows: false,
    };

    let agentTable;

    if (this.state.isLoading) {
      agentTable = <CircularProgress className={classes.progress} />;
    } else if (this.state.agents.length > 0) {
      agentTable = (
        <MUIDataTable
          title={"Agents"}
          data={this.state.agents}
          columns={columns}
          options={options}
        />
      );
    } else {
      agentTable = (
        <Typography varient="body1">
          No agents have been found. Start by adding an agent.
        </Typography>
      );
    }

    return (
      <div className={classes.root}>
        <CreateAgentDialog open={this.state.open} onClose={this.handleClose} />
        <Grid container spacing={0} className={classes.rowSpacing}>
          <Grid item xs={8} />
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.CreateAgentButtonClick}
            >
              Add an Agent
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            {agentTable}
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </div>
    );
  }
}

Agents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuth0(Agents));
