import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class SimpleDialog extends Component {
  cancelButtonClick = () => {
    this.props.onClose(false);
  };

  agreeButtonClick = () => {
    this.props.onClose(true);
  };

  render() {
    const { open, title, message, button1, button2 } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog"
      >
        <DialogTitle id="simple-dialog">{title}</DialogTitle>
        <DialogContent>
          <Typography component="p">{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancelButtonClick} color="primary">
            {button1 ? button1 : "Cancel"}
          </Button>
          <Button onClick={this.agreeButtonClick} color="primary" autoFocus>
            {button2 ? button2 : "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default withStyles(styles)(SimpleDialog);
