import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CampaignTabs from "./CampaignTabs";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import CampaignSettings from "./Campaign/CampaignSettings"

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing.unit * 2}px`,
  },
  column: {
    padding: theme.spacing.unit * 2,
  },
  rowSpacing: {
    padding: "20px",
  },
});

class CampaignLanding extends Component {
  state = {
    campaignName: "",
    agent_count: 0,
    contact_count: 0,
    goal_count: 0,
    campaignSettingsOpen: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    //get group details
    // fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/getbyid/" + id, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${this.props.auth.getAccessToken()}`
    //   }
    // })
    //   .then(response => {
    //     if (response.ok) return response.json();
    //     throw new Error("Network response was not ok.");
    //   })
    //   .then(response => {
    //     this.setState({ groupName: response[0].name });
    //   })
    //   .catch(error => this.setState({ message: error.message }));

    // fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/group/counts/" + id, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${this.props.auth.getAccessToken()}`
    //   }
    // })
    //   .then(response => {
    //     if (response.ok) return response.json();
    //     throw new Error("Network response was not ok.");
    //   })
    //   .then(response => {
    //     this.setState({
    //       agent_count: response[0].agent_count,
    //       contact_count: response[0].contact_count,
    //       goal_count: response[0].goal_count
    //     });
    //   })
    //   .catch(error => this.setState({ message: error.message }));
  }

  handleSettingsClick = () => {
    this.setState({campaignSettingsOpen: true});
  }

  handleCampaignSettingBack = () => {
    this.setState({campaignSettingsOpen: false});
  }

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;

    let campaignSettings;
    if(this.state.campaignSettingsOpen){
      campaignSettings = <CampaignSettings handleCampaignSettingBack={this.handleCampaignSettingBack} campaign_id={id}/>
    }else{
      campaignSettings = (
        <div>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              {this.state.campaignName}
            </Typography>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={1}>            
              <IconButton aria-label="Settings" onClick={this.handleSettingsClick}>
                <SettingsIcon fontSize="large" />
              </IconButton>
          </Grid>
        </Grid>
        <CampaignTabs
          agent_count={this.state.agent_count}
          goal_count={this.state.goal_count}
          contact_count={this.state.contact_count}
          campaign_id={this.props.match.params}
        />
      </div>
      )
    }

    return (
      <div>
        {campaignSettings}
      </div>
    );
  }
}

CampaignLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignLanding);
