import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import CreateContactDialog from "./CreateContactDialog";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignContactDialog from "./AssignContactDialog";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { withAuth0 } from "@auth0/auth0-react";

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing() * 4}px 0 ${theme.spacing() * 2}px`,
  },
  groupListItem: {
    cursor: "pointer",
  },
  createGroupButton: {
    marginTop: `${theme.spacing() * 2}px`,
  },
  column: {
    padding: theme.spacing() * 2,
  },
  rowSpacing: {
    padding: "20px",
  },
  progress: {
    margin: theme.spacing() * 2,
  },
});

class Contacts extends Component {
  state = {
    open: false,
    contacts: [],
    agents: [],
    isLoading: false,
    assignOpen: false,
    selectedAssignContactId: null,
  };

  componentDidMount() {
    this.getContacts();
  }

  getContacts = async () => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    let { id } = this.props.campaign_id;
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/get/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(response =>
        this.setState({
          contacts: response,
          isLoading: false
        })
      )
      .catch(error => this.setState({ message: error.message }));
  };


  // CreateContactButtonClick = () => {
  //   this.setState({
  //     open: true,
  //   });
  // };

  // handleClose = (contactToAdd) => {
  //   if (!contactToAdd.target) {
  //     this.getContacts();
  //   }
  //   this.setState({ open: false });
  // };

  // handleAssignClose = (assignAgent) => {
  //   if (!assignAgent.target) {
  //     let selectedContact = this.state.selectedAssignContactId;
  //     let contact = this.state.contacts.find(function (contact) {
  //       if (contact.contact_id === selectedContact) {
  //         return contact;
  //       }
  //     });
  //     this.AssignContactToAgent(assignAgent.value, contact, this.getContacts);
  //   }
  //   this.setState({ assignOpen: false, selectedAssignContactId: null });
  // };

  markContactInactive = async (contactId, _callback) => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/delete/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({contact_id: contactId})
    })
      .then(
        result => {
          if (result.ok) _callback();
          throw new Error("Network response was not ok.");
        },
        error => {
          console.log("error:" + error);
        }
      )
      .catch(error => this.setState({ message: error.message }));
  };


  render() {
    const { classes } = this.props;
    const { id } = this.props.campaign_id;

    const columns = [
      {
        name: "first_name",
        label: "First Name",
        options: {
          sort: true,
        },
      },
      {
        name: "last_name",
        label: "Last Name",
        options: {
          sort: true,
        },
      },
      {
        name: "phone",
        label: "Phone",
        options: {
          sort: true,
        },
      },
      {
        name: "address_1",
        label: "Address",
        options: {
          sort: true,
        },
      },
      {
        name: "city",
        label: "City",
        options: {
          sort: true,
        },
      },
      {
        name: "state",
        label: "State",
        options: {
          sort: true,
        },
      },
      {
        name: "zip",
        label: "Zip",
        options: {
          sort: true,
        },
      },
      {
        name: "gender",
        label: "Gender",
        options: {
          sort: true,
        },
      },
      {
        name: "polling_location",
        label: "Polling Location",
        options: {
          sort: true,
        },
      },
      {
        name: "party",
        label: "Party",
        options: {
          sort: true,
        },
      },
      {
        name: "contact_id",
        label: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                color="error"
                aria-label="Delete"
                onClick={() =>
                  this.markContactInactive(value, this.getContacts)
                }
              >
                <RemoveCircleIcon color="error" />
              </Button>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      print: false,
      selectableRows: false,
    };

    let contactsTable;

    if (this.state.isLoading) {
      contactsTable = <CircularProgress className={classes.progress} />;
    } else if (this.state.contacts.length > 0) {
      contactsTable = (
        <MUIDataTable
          title={"Manage Contacts"}
          data={this.state.contacts}
          columns={columns}
          options={options}
        />
      );
    } else {
      contactsTable = (
        <Typography varient="body1">
          No contacts have been found. Start by creating or importing contacts.
        </Typography>
      );
    }

    return (
      <div className={classes.root}>

        <Grid container spacing={0} className={classes.rowSpacing}>
          <Grid item xs={8} />
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              href={"/massupload/" + id}
            >
              Mass Import
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {contactsTable}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuth0(Contacts));
