import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "react-select";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  customDialog: {
    height: "200px",
  },
  root: { overflow: "visible" },
});

class AssignContactDialog extends Component {
  state = {
    selectedAgent: null,
  };

  handleCreate = () => {
    this.props.onClose(this.state.selectedAgent);
  };

  handleChange = (selectedAgent) => {
    this.setState({ selectedAgent });
  };

  formatAgents = (agentsToFormat) => {
    let returnList = [];
    agentsToFormat.forEach(function (agent) {
      returnList.push({
        label: agent.name + " - " + agent.email + " - " + agent.phone,
        value: agent,
      });
    });
    return returnList;
  };

  render() {
    const { classes, open, onClose } = this.props;
    const { selectedAgent } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
        maxWidth={"sm"}
        fullWidth={true}
        classes={{ paperScrollPaper: classes.root }}
      >
        <DialogTitle id="create-group-dialog">Assign to Agent</DialogTitle>
        <DialogContent className={classes.root}>
          <Select
            value={selectedAgent}
            onChange={this.handleChange}
            options={this.formatAgents(this.props.agents)}
            isClearable
            isSearchable
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleCreate} color="primary" autoFocus>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AssignContactDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(AssignContactDialog);
