import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import { Button } from "@material-ui/core";
import SimpleDialog from "../../components/SimpleDialog";
import Grid from "@material-ui/core/Grid";
import { withAuth0 } from "@auth0/auth0-react";


const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`
  },
  groupListItem: {
    cursor: "pointer"
  },
  createGroupButton: {
    marginTop: `${theme.spacing(2)}px`
  },
  column: {
    padding: theme.spacing(2)
  },
  rowSpacing: {
    padding: "20px"
  },
  textPadding: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  removeButtonMargin: {
    marginBottom: "20px"
  },
  settingTitle: {
    marginTop: "20px"
  }
});

class CampaignSettings extends Component {
  state = {
    open: false
  };

  constructor(props) {
    super(props);
  }

  openRemovePrompt = () => {
    this.setState({
      open: true
    });
  };

  handleClose = async (choice) => {
    const id  = this.props.campaign_id;
    let campaign = {campaign_id: id};
    if (choice) {
      const { getAccessTokenSilently } = this.props.auth0;
      const token = await getAccessTokenSilently();
      fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/delete", {
        method: 'POST',
        headers: {         
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(campaign)
      })
        .then((response) => {
          if (response.ok) return response.json();
          throw new Error("Network response was not ok.");
        })
        .then((response) => {          
          window.location.href = "/";
        })
        .catch(error => this.setState({ message: error.message }));

      //need to show confirmation of group removed
      //navigate to group page
      this.setState({ open: false });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{padding:'25px'}}>
        <SimpleDialog
          open={this.state.open}
          onClose={this.handleClose}
          title="Remove Campaign"
          message="Are you sure you want to remove this campaign?"
        />
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              Campaign Settings
            </Typography>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={1}>
            <Button variant="outlined" onClick={this.props.handleCampaignSettingBack}>
              Back
            </Button>
          </Grid>
        </Grid>

        <Divider />
        <Typography variant="h5" className={classes.settingTitle}>
          Remove Campaign
        </Typography>
        <Typography component="p" className={classes.textPadding}>
          Removing the campaign will delete the contacts, agents and script of the
          campaign.
        </Typography>
        <Button
          className={classes.removeButtonMargin}
          variant="contained"
          color="secondary"
          onClick={this.openRemovePrompt}
        >
          Remove Campaign
        </Button>
        <Divider />
      </div>
    );
  }
}

CampaignSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withAuth0(CampaignSettings));
