import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CreateCampaignDialog from "./CreateCampaign";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Group from "@material-ui/icons/Group";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withAuth0 } from "@auth0/auth0-react";

// import Hero from "../components/Hero";
// import Content from "../components/Content";
const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing() * 2,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing() * 4}px 0 ${theme.spacing() * 2}px`,
  },
  campaignListItem: {
    cursor: "pointer",
  },
  createCampaignButton: {
    marginTop: `${theme.spacing() * 2}px`,
  },
  column: {
    padding: theme.spacing() * 2,
  },
});

class Campaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      campaigns: [],
      message: "",
      isLoading: false,
      openDialog: false,
    };
    this.getCampaigns();
  }

  getCampaigns = async () => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/get", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) =>
        this.setState({ campaigns: response, isLoading: false })
      )
      .catch((error) => this.setState({ message: error.message }));
  };

  createCampaign = async (campaignToAdd) => {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ isLoading: true });
    fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/campaign/create", {
      method: "POST",
      headers: {        
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(campaignToAdd),
    })
      .then(
        (result) => {
          if (result.ok) return result.json();
          throw new Error("Network response was not ok.");
        },
        (error) => {
          console.log("error:" + error);
        }
      )
      .then((response) => {
        this.setState({ isLoading: false,  openDialog: false});
        this.getCampaigns();
      })
      .catch((error) => this.setState({ message: error.message }));
  };

  handleClickOpen = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleClose = (campaignToAdd) => {
    if (campaignToAdd && !campaignToAdd.target) {
      this.createCampaign(campaignToAdd);
    }
    this.setState({ openDialog: false });
  };

  render() {
    const { classes } = this.props;

    let campaignList;

    if (this.state.isLoading) {
      campaignList = <CircularProgress className={classes.progress} />;
    } else if (this.state.campaigns) {
      campaignList = (
        <List>
          {this.state.campaigns.map((campaign, index) => (
            <>
              {index !== 0 && <Divider variant="inset" component="li" />}
              <ListItem
                button
                component={Link}
                to={"/campaign/" + campaign.campaign_id}
                key={campaign.name}
                className={classes.campaignListItem}
              >
                <ListItemAvatar>
                  <Avatar>
                    <Group />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={campaign.name}
                  secondary={campaign.city + ", " + campaign.state + " " + campaign.phone}
                />
              </ListItem>
            </>
          ))}
        </List>
      );
    }

    return (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Campaigns
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Typography variant="h4" className={classes.title}>
              Choose an existing campaign
            </Typography>
            <Paper className={classes.demo}>{campaignList}</Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" className={classes.title}>
              Start a new campaign
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleClickOpen}
              className={classes.createCampaignButton}
            >
              Start a Campaign
            </Button>
            <CreateCampaignDialog
              open={this.state.openDialog}
              onClose={this.handleClose}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(withAuth0(Campaigns));
