import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import OptOutContact from "./OptOutErrorDialog";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

const REACT_APP_AWS_GATEWAY_DOMAIN = process.env.REACT_APP_AWS_GATEWAY_DOMAIN;

class CreateContactDialog extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone: "",
    phoneError: false,
    isLoading: false,
    contact_id: -1,
    optoutError: false,
  };

  componentDidMount() {
    this.setState({ optoutError: false, phoneError: false });
  }

  createContact = (firstName, lastName, phone) => {
    this.setState({ isLoading: true });
    // fetch(REACT_APP_AWS_GATEWAY_DOMAIN + "/contact/create", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${this.props.auth.getAccessToken()}`
    //   },
    //   body: JSON.stringify({group_id: this.props.groupId, first_name: firstName, last_name: lastName, phone: phone})
    // })
    //   .then(response => {
    //     if (response.ok) return response.json();
    //     throw new Error("Network response was not ok.");
    //   }).then(response => {
    //     if (response[0].create_contact === -1){
    //       this.setState({optoutError: true, isLoading:false});
    //     }else{
    //       this.setState({isLoading: false});
    //       this.props.onClose({contact_id: response[0].create_contact, first_name: firstName, last_name: lastName, phone: phone});
    //     }
    //   })
    //   .catch(error => this.setState({ message: error.message, isLoading: false }));
  };

  handleCreate = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (this.state.phone.match(phoneno)) {
      this.createContact(
        this.state.first_name,
        this.state.last_name,
        this.state.phone
      );
    } else {
      this.setState({ phoneError: true });
    }
  };

  optOutValidation = () => {
    let contact = {
      contact_id: -1,
      // first_name: this.state.first_name,
      // last_name: this.state.last_name,
      // phone: this.state.phone
    };
    if (!this.state.optoutError) {
      this.props.onClose(contact);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, open, onClose } = this.props;
    const { isLoading, optoutError } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="create-group-dialog"
      >
        <DialogTitle id="create-group-dialog">Create a Contact</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="create-contact-firstName"
            label="First Name"
            className={classes.textField}
            onChange={this.handleChange("first_name")}
            margin="normal"
            name="contact.first_name"
          />

          <TextField
            autoFocus
            id="create-contact-lastName"
            label="Last Name"
            className={classes.textField}
            onChange={this.handleChange("last_name")}
            margin="normal"
            name="contact.last_name"
          />

          <TextField
            error={this.state.phoneError}
            required
            autoFocus
            id="create-contact-phone"
            label="Phone Number"
            className={classes.textField}
            onChange={this.handleChange("phone")}
            margin="normal"
            name="contact.phone"
          />
          {/* <OptOutContact hidden={!optoutError} /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={this.handleCreate}
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateContactDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(CreateContactDialog);
